import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AuthStatus, useAuth } from '../auth';
import { DataGrid } from '@mui/x-data-grid';
import '../styles/adminCompanies.scss';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from './navbar';
import Menu   from './menu';


import Modal from 'bootstrap/js/dist/modal';


const endpoint = process.env.REACT_APP_ENDPOINT;

const AdminCompanies = () => {
    const { token } = useAuth();
    const [companies, setCompanies] = useState([]);
    const [newCompany, setNewCompany] = useState({
        nombre: '',
        tipo_persona: 'Física',
        abreviacion: '',
        otra_abreviacion: '',
        representante_legal: '',
        rfc: '',
        giro_actividad: '',
        domicilios: [
            {
                tipo: '',
                calle: '',
                numero_exterior: '',
                numero_interior: '',
                codigo_postal: '',
                colonia: '',
                estado: '',
                ciudad: '',
                is_fiscal: false,
            }
        ],
        benefits: {
            tipo: '',
            vacaciones_anuales: [
                { "years": 1, "days": 12 },
            ],
            prima_vacacional: 0,
            aguinaldo: 0,
        }
    });
    const [selectedCompany, setSelectedCompany] = useState({});
    const [error, setError] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [editCompany, setEditCompany] = useState(null);
    const [selectAddress, setAddress] = useState(null);
    const [fetch, setFetch] = useState(false);

    const [callback, setCallback] = useState(()=>{})

    const status = AuthStatus();

    const fetchCompanies = async () => {
        try {
            const response = await axios.get(`${endpoint}admin/companies`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        
            setCompanies(response.data);
        } catch (error) {
            setError('Error al obtener la lista de empresas');
        }
    };

    const createCompany = async (e) => {
        if(newCompany.rfc.length < 12){
            toast.error("Complete su RFC")
        }else if(newCompany.tipo_persona != 'moral' && newCompany.rfc.length < 13){
            toast.error("Complete su RFC")
        }else{
            e.target.classList.add('-disable')    
            let vacations = JSON.parse(document.querySelector("input[name='vacaciones_anuales']").value)
            let vacationsArray = Object.entries(vacations).map(([year, days]) => ({ years: year, days: days }));
            let updated = {
                ...newCompany,
                benefits: {
                ...newCompany.benefits,
                vacaciones_anuales: vacationsArray
                }
            }

            try {

                const response = await axios.post(`${endpoint}admin/companies`, updated, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            
                setNewCompany({
                    nombre: '',
                    tipo_persona: '',
                    abreviacion: '',
                    otra_abreviacion: '',
                    representante_legal: '',
                    rfc: '',
                    giro_actividad: '',
                    domicilios: [
                        {
                            tipo: '',
                            calle: '',
                            numero_exterior: '',
                            numero_interior: '',
                            codigo_postal: '',
                            colonia: '',
                            estado: '',
                            ciudad: '',
                            is_fiscal: false,
                        }
                    ],
                    benefits: {
                        ley: false,
                        superiores: false,
                        vacaciones_anuales: [],
                        prima_vacacional: 0,
                        aguinaldo: 0,
                    }
                });
            
                toast.success("Empresa creada");
                fetchCompanies()
                document.getElementById('addCompany').click();
            } catch (error) {
                console.log(error)
                
                const errors = error.response.data.errors;
                if (typeof errors === 'object') {
                    const errorMessages = Object.entries(errors)
                        .flatMap(([field, messages]) =>
                            messages.map(message => `${field}: ${message}`)
                        )
                        .join('\n');
                
                    toast.error("Errores al crear la empresa:\n" + errorMessages);
                } else {
                    toast.error("Error al crear la empresa: " + error.response.data.error);
                }

                document.getElementById('addCompany').click();
            }
            
            e.target.classList.remove('-disable')
            
        }
       
        
      
    };

    const updateCompany = async (id ,e) => {
        e.target.classList.add('-disable')
        setFetch(true);
        let vacations = JSON.parse(document.querySelector("input[name='edit-vacaciones_anuales']").value)
        let vacationsArray = Object.entries(vacations).map(([year, days]) => ({ years: year, days: days }));
        let updated = {
            ...editCompany,
            benefits: {
              ...editCompany.benefits,
              vacaciones_anuales: vacationsArray
            }
        }
          
        try {
            
            const response = await axios.put(`${endpoint}admin/companies/${id}`, updated, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            // Actualizamos la compañía con los datos más recientes de la respuesta
             const updatedCompanies = companies.map(company => {
                if (company.id === id) {
                    // Mantén los addresses intactos y actualiza el resto de la información
                    return { 
                        ...company, 
                        ...response.data, 
                        addresses: company.addresses 
                    };
                }
                return company;
            });
         
            setSelectedCompany(response.data)

            console.log(updatedCompanies)
            setFetch(false);
            toast.success("Empresa actualizada");
            fetchCompanies();
        } catch (error) {
            setFetch(false);
            console.log(error)
            const errors = error.response.data.errors;
            if (typeof errors === 'object') {
                const errorMessages = Object.entries(errors)
                    .flatMap(([field, messages]) =>
                        messages.map(message => `${field}: ${message}`)
                    )
                    .join('\n');
            
                toast.error("Errores al actualizar:\n" + errorMessages);
            } else {
                toast.error("Error al actualizar: " + errors);
            }
            
        }
        
        e.target.classList.remove('-disable')
    };

    const deleteCompany = async (id, e) => {
        e.target.classList.add("-disable")
        confirmDelete(()=>deleteCompanyFetch(id))
        e.target.classList.remove('-disable')
    };

    const deleteCompanyFetch = async (id) =>{
        try {
            await axios.delete(`${endpoint}admin/companies/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const updatedCompanies = companies.filter(company => company.id !== id);
            setCompanies(updatedCompanies);
            //console.log(document.getElementById('viewCompanyButton'))
            //document.getElementById('viewCompanyButton').click()
            setSelectedCompany(null);
            toast.success("Empresa eliminada");
        } catch (error) {
            setError('Error al eliminar la empresa');
            toast.error("Error al eliminar la empresa");
        }
    }

    useEffect(() => {
        fetchCompanies();
    }, [token]);

    const filteredCompanies = companies
  .filter(company =>
    company.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
    company.rfc.toLowerCase().includes(searchTerm.toLowerCase())
  )
  .sort((a, b) => {
    const getLatestDate = (company) => {
      const benefitDate = company.benefits?.updated_at ? new Date(company.benefits.updated_at) : new Date(0); // Default to an earliest possible date if no benefit date
      const companyDate = new Date(company.updated_at);
      return Math.max(companyDate, benefitDate);
    };

    return getLatestDate(b) - getLatestDate(a);
  });


    const columns = [
        
        { field: 'nombre', headerName: 'Nombre', width: 200 },
        { 
            field: 'tipo_persona', 
            headerName:'Persona', 
            width: 200,
            valueGetter: (params) => params === 'moral' ? 'Moral' : 'Fisica'
        },
        { 
            field: 'addresses', 
            headerName: 'Domicilios', 
            width: 200,
            valueGetter: (params) => params ? params.length : 0
        },
        { field: 'rfc', headerName: 'RFC', width: 150 },
        { field: 'giro_actividad', headerName: 'Giro/Actividad', width: 200 },
    ];

    const addressColumns = [
        
        { field: 'calle', headerName: 'Calle', width: 200 },
        { field: 'numero_exterior', headerName: '# exterior', width: 100 },
        { field: 'numero_interior', headerName: '# interior', width: 100 },
        { field: 'ciudad', headerName: 'Ciudad', width: 200 },
        { field: 'codigo_postal', headerName: 'Codigo postal', width: 100 },
        { field: 'colonia', headerName: 'Colonia', width: 200 },
        { field: 'estado', headerName: 'Estado', width: 200 },

        
    ];

    const handleAddAddress = () =>{
        setAddress(
                {
                    calle: '',
                    numero_exterior: '',
                    numero_interior: '',
                    codigo_postal: '',
                    colonia: '',
                    estado: '',
                    ciudad: '',
                    is_fiscal: false,
                }
        );
    }

    const handleUpdateAddress = async (e) => {
       
        e.target.classList.add('-disable')
        try {
            let res = '';
            if (selectAddress.company_id) {
                // El address ya está registrado, solo hay que actualizar
                const response = await axios.put(`${endpoint}admin/addresses/${selectAddress.id}`, selectAddress, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                res = response.data;
    
                // Sustituir el address con el mismo id por el actualizado en response.data
                setEditCompany((prev) => ({
                    ...prev,
                    addresses: prev.addresses.map((address) =>
                        address.id === selectAddress.id ? res : address
                    ),
                }));
                toast.success("Domicilio actualizado");
            } else {
                // Crear nuevo address
                const newAddress = { ...selectAddress, company_id: editCompany.id };
                const response = await axios.post(`${endpoint}admin/addresses`, newAddress, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                res = response.data;
    
                // Agregar el nuevo address que viene en response.data
                setEditCompany((prev) => ({
                    ...prev,
                    addresses: [...prev.addresses, res],
                }));
                toast.success("Domicilio agregado");
            }
            document.getElementById('closeAddressOff').click()
    
           
        } catch (error) {
            console.log(error);
           
            const errors = error.response.data.errors;
            if (typeof errors === 'object') {
                const errorMessages = Object.entries(errors)
                    .flatMap(([field, messages]) =>
                        messages.map(message => `${field}: ${message}`)
                    )
                    .join('\n');
            
                toast.error("Errores al agregar/actualizar domicilio:\n" + errorMessages);
            } else {
                toast.error("Error al agregar/actualizar domicilio: " + errors);
            }
        }
        e.target.classList.remove('-disable')
    };
    
    
    const handleRemoveAddress = async (e) => {
        e.target.classList.add('-disable')
        confirmDelete(() => {removeAddress()})
        
        e.target.classList.remove('-disable')
        
    };

    const removeAddress = async () => {
        if(selectAddress.id){
            const addressId = selectAddress.id;
        
            try {
                await axios.delete(`${endpoint}admin/addresses/${addressId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const newDomicilios = editCompany.addresses.filter((item, i) => item.id !== addressId);
                setEditCompany({ ...editCompany, addresses: newDomicilios });
                setCompanies((companies) => companies.map((company) =>
                    company.id === editCompany.id ? { ...company, addresses: newDomicilios } : company
                ));

                toast.success("Domicilio eliminado");
            } catch (error) {
                setError('Error al eliminar el domicilio');
                toast.error("Error al eliminar el domicilio");
            }
        }else{
            const newDomicilios = editCompany.addresses.filter((item, i) => item.calle !== selectAddress.calle);
            setEditCompany({ ...editCompany, addresses: newDomicilios });
            setCompanies((companies) => companies.map((company) =>
                company.id === editCompany.id ? { ...company, addresses: newDomicilios } : company
            ));
            console.log(newDomicilios)
            toast.success("Domicilio eliminado");
        }
    }
    
    const handleSelectChange = (e) => {
        const tipo = e.target.value;
        let benefits = { ...newCompany.benefits, tipo };
    
        if (tipo === 'ley') {
            benefits = {
                ...benefits,
                vacaciones_anuales: [
                    { "years": 1, "days": 12 },
                    { "years": 2, "days": 14 },
                    { "years": 3, "days": 16 },
                    { "years": 4, "days": 18 },
                    { "years": 5, "days": 20 },
                    { "years": 6, "days": 22 },
                    { "years": 11, "days": 24 },
                    { "years": 16, "days": 26}
                ],  
                prima_vacacional: 25,
                aguinaldo: 15,
                ley: true,
                superiores: false,
            };
           
        }else{
            benefits = {
                ...benefits,
                vacaciones_anuales: [
                    {"years" : 1, "days": 12}
                ],
                prima_vacacional: 25,
                aguinaldo: 15,
                ley: false,
                superiores: true,
            }
        }
    
        setNewCompany({ 
            ...newCompany, 
            benefits 
        });
    };

    const handleSelectEditChange = (e) => {
        const tipo = e.target.value;
        let benefits = { ...editCompany.benefits };
    
        if (tipo === 'ley') {
            benefits = {
                ...benefits,
                vacaciones_anuales: [
                    { "years": 1, "days": 12 },
                    { "years": 2, "days": 14 },
                    { "years": 3, "days": 16 },
                    { "years": 4, "days": 18 },
                    { "years": 5, "days": 20 },
                    { "years": 6, "days": 22 },
                    { "years": 11, "days": 24 },
                    { "years": 16, "days": 26}
                ], 
                prima_vacacional: 25,
                aguinaldo: 15,
                ley: true,
                superiores: false
            };
        }else{
            benefits = {
                ...benefits, 
                superiores: true,
                ley: false,
                prima_vacacional: 25,
                aguinaldo: 15,
                vacaciones_anuales:[
                    {"years": 1, "days": 12}
                ]
            }
        }
    
        setEditCompany({ 
            ...editCompany, 
            benefits 
        });
    };

    const confirmDelete = (callback) =>{  
        //document.getElementById('viewDeleteModal').click()
        const deleteModal = new Modal(document.getElementById('deleteModal')); // Uso de window.bootstrap
        deleteModal.show();
        setCallback(()=>callback);
        
    }

   


    return (
        <div className='admin-companies'>
            <NavBar auth={status} companies={companies} selected={selectedCompany} set={setSelectedCompany}/>

            <div className="admin-companies-row">
                <Menu />

                <div className='admin-companies-wrapper'>
                    <ToastContainer />
                    <h2>Patrones</h2>
                    {error && <p>{error}</p>}

                    <div className="admin-companies-controls">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Buscar empresas..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <button className="btn btn-primary btn-rounded" id='addCompany' type="button" data-bs-toggle="offcanvas" data-bs-target="#createCompanyOffcanvas" aria-controls="createCompanyOffcanvas">
                            Agregar patrón
                        </button>
                        <button className="btn btn-primary me-2" id='viewCompanyButton' type="button" data-bs-toggle="offcanvas" data-bs-target="#viewCompanyOffcanvas" aria-controls="viewCompanyOffcanvas">
                            Ver patrón
                        </button>
                    </div>

                    <div style={{ height: '1000px', width: '100%' }} className='admin-companies-table'>
                        <DataGrid
                            rows={filteredCompanies}
                            columns={columns}
                            sx={{ borderColor: 'white' }}
                            pageSize={5}
                            onRowClick={(row) => {console.log(row.row); setSelectedCompany(row.row); setEditCompany(row.row); document.getElementById('viewCompanyButton').click(); console.log(row.row)}}
                        />
                    </div>

                    {/* Agregar */}
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="createCompanyOffcanvas" aria-labelledby="createCompanyOffcanvasLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="createCompanyOffcanvasLabel">Agregar patrón</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            
                            <div className="c-input">
                            <label htmlFor="tipo_persona">Tipo de Persona</label>
                            <select 
                                name='tipo_persona' 
                                value={newCompany.tipo_persona} 
                                onChange={(e) => setNewCompany({ ...newCompany, tipo_persona: e.target.value })} 
                                className="form-control mb-3"
                            >
                                <option value="fisica">Física</option>
                                <option value="moral">Moral</option>
                            </select>
                            </div>

                            <div className="c-input">
                                <label htmlFor="nombre">{newCompany.tipo_persona === 'moral' ? 'Razón Social': 'Nombre'}</label>
                                <input type="text" name='nombre' value={newCompany.nombre} onChange={(e) => setNewCompany({ ...newCompany, nombre: e.target.value })} className="form-control mb-3" />
                            </div>

                            {
                                newCompany.tipo_persona == 'moral' &&
                                <div className="c-input">
                                    <label htmlFor="abreviacion">Tipo</label>
                                    <select name='abreviacion' value={newCompany.abreviacion} onChange={(e) => setNewCompany({ ...newCompany, abreviacion: e.target.value })} className="form-control mb-3">
                                        <option value="" disabled selected>Seleccione una opción</option>
                                        <option value="S.A. de C.V.">S.A. de C.V.</option>
                                        <option value="S. de R.L. de C.V.">S. de R.L. de C.V.</option>
                                        <option value="A.C.">A.C. (Asociación Civil)</option>
                                        <option value="S.C.">S.C. (Sociedad Civil)</option>
                                        <option value="S.N.C.">Sociedad en Nombre Colectivo</option>
                                        <option value="S.A.">S.A. (Sociedad Anónima)</option>
                                        <option value="S. en C.">S. en C. (Sociedad en Comandita Simple)</option>
                                        <option value="S. en C. por A.">S. en C. por A. (Sociedad en Comandita por Acciones)</option>
                                        <option value="SOCIEDAD COOPERATIVA">Sociedad Cooperativa</option>
                                        <option value="S.A.S.">S.A.S. (Sociedad por Acciones Simplificada)</option>
                                        <option value="S.A.P.I.">S.A.P.I. (Sociedad Anónima Promotora de Inversión)</option>
                                        <option value="Otra">Otra</option>
                                    </select>
                                    {newCompany.abreviacion === 'Otra' && (
                                        <div className="c-input">
                                            <label htmlFor="otra_abreviacion">Otra Abreviación</label>
                                            <input type="text" name='otra_abreviacion' value={newCompany.otra_abreviacion} onChange={(e) => setNewCompany({ ...newCompany, otra_abreviacion: e.target.value })} className="form-control mb-3" />
                                        </div>
                                    )}
                                </div>

                            }

                            {
                                newCompany.tipo_persona == 'moral' &&
                                <div className="c-input">
                                    <label htmlFor="representante_legal">Representante Legal</label>
                                    <input type="text" name='representante_legal' value={newCompany.representante_legal} onChange={(e) => setNewCompany({ ...newCompany, representante_legal: e.target.value })} className="form-control mb-3" />
                                </div>
                            }

                            
                            
                            <div className="c-input">
                                <label htmlFor="rfc">RFC</label>
                                <input 
                                    type="text" 
                                    minLength={12} 
                                    maxLength={13} 
                                    name='rfc' 
                                    value={newCompany.rfc} 
                                    onChange={(e) => setNewCompany({ ...newCompany, rfc: e.target.value })} 
                                    className="form-control mb-3" 
                                />
                            </div>
                            <div className="c-input">
                                <label htmlFor="giro_actividad">Actividad{newCompany.tipo_persona && newCompany.tipo_persona == 'moral' ? '/Objeto Social': '/Giro'}</label>
                                <input type="text" name='giro_actividad' value={newCompany.giro_actividad} onChange={(e) => setNewCompany({ ...newCompany, giro_actividad: e.target.value })} className="form-control mb-3" />
                            </div>          

                            <div className="c-input">
                            <label htmlFor="tipo">Prestaciones</label>
                            <select 
                                name="tipo" 
                                value={newCompany.benefits.ley === true ? 'ley' : newCompany.benefits.superiores == true ? 'superiores' : ''} 
                            
                                onChange={handleSelectChange}
                                className="form-select mb-3"
                            >
                                <option value="" disabled>Tipo</option>
                                <option value="ley">Las de ley</option>
                                <option value="superiores">Superiores</option>
                            </select>

                            </div>

                            

                            

                            <div className="c-input">
                                <label htmlFor="vacaciones_anuales"><strong>Vacaciones</strong></label>
                                <VacationYearsInput disabled={newCompany.benefits.ley}  data={newCompany.benefits.vacaciones_anuales}></VacationYearsInput>
                            </div>


                            <div className="c-input">
                                <label htmlFor="prima_vacacional">Prima Vacacional (%)</label>
                                <input disabled={newCompany.benefits.ley ? true : false} type="number" name="prima_vacacional" value={newCompany.benefits.prima_vacacional} onChange={(e) => setNewCompany({ ...newCompany, benefits: { ...newCompany.benefits, prima_vacacional: e.target.value } })} className="form-control mb-3" />
                            </div>
                            <div className="c-input">
                                <label htmlFor="aguinaldo">Aguinaldo (Días)</label>
                                <input disabled={newCompany.benefits.ley ? true : false} type="number" name="aguinaldo" value={newCompany.benefits.aguinaldo} onChange={(e) => setNewCompany({ ...newCompany, benefits: { ...newCompany.benefits, aguinaldo: e.target.value } })} className="form-control mb-3" />
                            </div>

                            <span className='buttons'>
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="offcanvas" aria-label="Close">Cancelar</button>                        
                                <button className="btn btn-primary" onClick={(e)=>createCompany(e)}>Crear</button>
                            </span>
                           
                        </div>
                    </div>

                    {/* Editar */}
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="editCompanyOffcanvas" aria-labelledby="editCompanyOffcanvasLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="viewCompanyOffcanvasLabel">Detalles</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            {editCompany && (
                                <div>
                                    <div className="c-input">
                                        <label htmlFor="tipo_persona">Tipo de Persona</label>
                                        <select 
                                            name='tipo_persona' 
                                            value={editCompany.tipo_persona} 
                                            onChange={(e) => setEditCompany({ ...editCompany, tipo_persona: e.target.value })} 
                                            className="form-control mb-3"
                                        >
                                            <option value="fisica">Física</option>
                                            <option value="moral">Moral</option>
                                        </select>
                                    </div>
                                    
                                    <div className="c-input">
                                        <label htmlFor="edit-nombre_patron">{editCompany.tipo_persona === 'moral' ? 'Razón Social': 'Nombre'}</label>
                                        <input type="text" name='edit-nombre_patron' value={editCompany.nombre} onChange={(e) => setEditCompany({ ...editCompany, nombre: e.target.value })} className="form-control mb-3" />
                                    </div>
                                    <div className="c-input">
                                        <label htmlFor="edit-rfc">RFC</label>
                                        <input type="text" name='edit-rfc' value={editCompany.rfc} onChange={(e) => setEditCompany({ ...editCompany, rfc: e.target.value })} className="form-control mb-3" />
                                    </div>
                                    <div className="c-input">
                                        <label htmlFor="edit-giro_actividad">Giro/Actividad</label>
                                        <input type="text" name='edit-giro_actividad' value={editCompany.giro_actividad} onChange={(e) => setEditCompany({ ...editCompany, giro_actividad: e.target.value })} className="form-control mb-3" />
                                    </div>

                                    <div className="c-input">
                                        <label htmlFor="tipo">Prestaciones</label>
                                        <select 
                                            name="tipo" 
                                            value={editCompany.benefits.ley == true ? 'ley' : editCompany.benefits.superiores == true ? 'superiores' : ''} 
                                            onChange={handleSelectEditChange}
                                            className="form-select mb-3"
                                        >
                                            <option value="" disabled>Tipo</option>
                                            <option value="ley">Las de ley</option>
                                            <option value="superiores">Superiores</option>
                                        </select>

                                    </div>
                                    
                                    <div className="c-input">
                                        <label htmlFor="edit-vacaciones_anuales">Vacaciones(Días)</label>
                                        {
                                            Array.isArray(editCompany.benefits.vacaciones_anuales) ?
                                            <VacationYearsInput set={setEditCompany} disabled={editCompany.benefits.ley} data={editCompany.benefits.vacaciones_anuales} edit={true}></VacationYearsInput>
                                            :
                                            <VacationYearsInput set={setEditCompany} disabled={editCompany.benefits.ley} data={editCompany.benefits.vacaciones_anuales.length > 4 ? JSON.parse(editCompany.benefits.vacaciones_anuales) : []} edit={true}></VacationYearsInput>
                                        }
                                    </div>
                                    <div className="c-input">
                                        <label htmlFor="edit-prima_vacacional">Prima Vacacional (%)</label>
                                        <input type="number" disabled={editCompany.benefits.ley} name="edit-prima_vacacional" value={editCompany.benefits.prima_vacacional} onChange={(e) => setEditCompany({ ...editCompany, benefits: { ...editCompany.benefits, prima_vacacional: e.target.value } })} className="form-control mb-3" />
                                    </div>
                                    <div className="c-input">
                                        <label htmlFor="edit-aguinaldo">Aguinaldo (Días)</label>
                                        <input type="number" disabled={editCompany.benefits.ley} name="edit-aguinaldo" value={editCompany.benefits.aguinaldo} onChange={(e) => setEditCompany({ ...editCompany, benefits: { ...editCompany.benefits, aguinaldo: e.target.value } })} className="form-control mb-3" />
                                    </div>

                                    
                                        
                                    <hr/>

                                    <span>
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="offcanvas" aria-label="Close" onClick={()=>{document.getElementById('viewCompanyButton').click()}}>Cancelar</button>
                                        <button className="btn btn-primary" onClick={(e) => updateCompany(selectedCompany.id,e)}>Guardar</button>
                                    </span>
                                    
                                

                                    
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Ver */}
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="viewCompanyOffcanvas" aria-labelledby="viewCompanyOffcanvasLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="viewCompanyOffcanvasLabel">Detalles</h5>
                            <button type="button" className="btn-close" id='closeCompany' data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            {editCompany && (
                                <div>
                                    <div className="c-input">
                                        <label htmlFor="tipo_persona">Tipo de Persona</label>
                                        <p>{editCompany.tipo_persona === 'moral'  ? 'Moral' : 'Fisica'}</p>
                                        
                                    </div>
                                    
                                    <div className="c-input">
                                        <label htmlFor="edit-nombre_patron">{editCompany.tipo_persona === 'moral' ? 'Razón Social': 'Nombre del Patrón'}</label>
                                        <p>{editCompany.nombre}</p>
                                    </div>
                                    <div className="c-input">
                                        <label htmlFor="edit-rfc">RFC</label>
                                        <p>{editCompany.rfc}</p>
                                    </div>
                                    <div className="c-input">
                                        <label htmlFor="edit-giro_actividad">Giro/Actividad</label>
                                        <p>{editCompany.giro_actividad}</p>
                                    </div>

                                    <div className="c-input">
                                        <label htmlFor="tipo">Prestaciones</label>
                                        <p>{editCompany.benefits.ley == true ? 'Ley' : 'Superiores'} </p>

                                    </div>
                                    
                                    <div className="c-input">
                                        <label htmlFor="edit-vacaciones_anuales">Vacaciones Anuales</label>
                                        <CompanyVacationDisplay editCompany={editCompany}   />
                                    </div>
                                    <div className="c-input">
                                        <label htmlFor="edit-prima_vacacional">Prima Vacacional</label>
                                        <p>{editCompany.benefits.prima_vacacional}%</p>
                                    </div>
                                    <div className="c-input">
                                        <label htmlFor="edit-aguinaldo">Aguinaldo</label>
                                        <p>{editCompany.benefits.aguinaldo} dias de sueldo</p>
                                    </div>
                                    
                                    <span>
                                        <hr/>
                                        <strong>Acciones</strong>
                                        <button type="button" class="btn btn-primary" onClick={()=>{document.getElementById('closeCompany').click()}} id='viewAddresses' data-bs-toggle="modal" data-bs-target="#addressesModal">
                                            Domicilios
                                        </button>
                                        
                                        <button className="btn btn-edit" data-bs-toggle="offcanvas" data-bs-target="#editCompanyOffcanvas">Editar</button>
                                        <button className="btn btn-danger" onClick={(e) => deleteCompany(selectedCompany.id,e)}>Eliminar</button>

                                    </span>

                                    
                                </div>
                            )}
                        </div>
                    </div>
                    
                    {/* Domicilios */} 
                    <div className="modal fade" id="addressesModal" tabindex="-1" aria-labelledby="addressesModalLabel" aria-hidden="true" data-bs-focus="false">
                        <div className="modal-dialog modal-fullscreen">
                            <div className="modal-content">

                            
                            
                            <div className="modal-body">
                                
                                <NavBar auth={status} companies={companies} selected={selectedCompany} set={setEditCompany} />


                                <div className="admin-addresses-controls">
                                    <button type="button" className="btn btn-back" data-bs-dismiss="modal">Volver</button>
                                
                                    <button className="btn btn-primary btn-rounded" id='addAddress' onClick={()=>handleAddAddress()} type="button" data-bs-toggle="offcanvas" data-bs-target="#addressCompanyOffcanvas" aria-controls="createAddressOffcanvas">
                                        Agregar domicilio
                                    </button>
                                    <button className="btn btn-primary me-2" id='viewAddress' type="button" data-bs-toggle="offcanvas" data-bs-target="#addressCompanyOffcanvas" aria-controls="viewCompanyOffcanvas">
                                        Ver domicilio
                                    </button>
                                </div>

                                
                                <div style={{ height: '80vh', width: '100%' }} className='admin-addresses-table'>
                                    <DataGrid
                                        rows={editCompany ? editCompany.addresses : []}
                                        columns={addressColumns}
                                        sx={{ borderColor: 'white' }}
                                        pageSize={5}
                                        onRowClick={(row) => { document.getElementById('viewAddress').click(); setAddress(row.row); }}
                                    />
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                
                            </div>
                            </div>
                        </div>
                    </div>

                    {/* Agregar/editar Domicilios */}
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="addressCompanyOffcanvas" aria-labelledby="addressCompanyOffcanvasLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="addressCompanyOffcanvasLabel">Domicilios</h5>
                            {/* <button type="button" className="btn-close" id='closeAddressOff' data-bs-dismiss="offcanvas" aria-label="Close"></button> */}
                        </div>
                        <div className="offcanvas-body">
                             
                            {selectAddress && ( 

                                <div >
                                    <div className="c-input">
                                        <label htmlFor={`calle`}>Calle</label>
                                        <input type="text" name={`calle`} value={selectAddress.calle} onChange={(e) => {
                                            setAddress({ ...selectAddress, calle: e.target.value });
                                        }} className="form-control mb-3" />
                                    </div>
                                    <div className="c-input">
                                        <label htmlFor={`numero_exterior`}>Número Exterior</label>
                                        <input type="text" name={`numero_exterior`} value={selectAddress.numero_exterior} onChange={(e) => {
                                            setAddress({ ...selectAddress, numero_exterior: e.target.value });
                                        }} className="form-control mb-3" />
                                    </div>
                                    <div className="c-input">
                                        <label htmlFor={`numero_interior`}>Número Interior</label>
                                        <input type="text" name={`numero_interior`} value={selectAddress.numero_interior} onChange={(e) => {
                                            setAddress({ ...selectAddress, numero_interior: e.target.value });
                                        }} className="form-control mb-3" />
                                    </div>
                                    <div className="c-input">
                                        <label htmlFor={`codigo_postal`}>Código Postal</label>
                                        <input type="text" name={`codigo_postal`} value={selectAddress.codigo_postal} onChange={(e) => {
                                            setAddress({ ...selectAddress, codigo_postal: e.target.value });
                                        }} className="form-control mb-3" />
                                    </div>
                                    <div className="c-input">
                                        <label htmlFor={`colonia`}>Colonia</label>
                                        <input type="text" name={`colonia`} value={selectAddress.colonia} onChange={(e) => {
                                            setAddress({ ...selectAddress, colonia: e.target.value });
                                        }} className="form-control mb-3" />
                                    </div>
                                    <div className="c-input">
                                        <label htmlFor={`estado`}>Estado</label>
                                        <input type="text" name={`estado`} value={selectAddress.estado} onChange={(e) => {
                                            setAddress({ ...selectAddress, estado: e.target.value });
                                        }} className="form-control mb-3" />
                                    </div>
                                    <div className="c-input">
                                        <label htmlFor={`ciudad`}>Ciudad</label>
                                        <input type="text" name={`ciudad`} value={selectAddress.ciudad} onChange={(e) => {
                                            setAddress({ ...selectAddress, ciudad: e.target.value });
                                        }} className="form-control mb-3" />
                                    </div>
                                    
                                    {editCompany.addresses.some(address => address.is_fiscal && address.id !== selectAddress.id) ? (
                                        // Si hay otro domicilio fiscal y no es el actual, ocultar el checkbox
                                        <input name="is_fiscal" value={selectAddress.is_fiscal} type="hidden" />
                                        ) : (
                                        <div className="c-input">
                                            <input
                                            type="checkbox"
                                            id="is_fiscal"
                                            name="is_fiscal"
                                            checked={selectAddress.is_fiscal === 1}
                                            onChange={(e) => setAddress({ ...selectAddress, is_fiscal: e.target.checked ? 1 : 0 })}
                                            className="form-check-input mb-3"
                                            />
                                            <label htmlFor="is_fiscal">Establecer como domicilio fiscal</label>
                                        </div>
                                    )}
                                        
                                    <span>
                            
                                        <button className="btn btn-primary mt-3" onClick={(e)=>handleUpdateAddress(e)}>Guardar</button>

                                        {selectAddress.id && (
                                            
                                            <button className="btn btn-danger mb-3" onClick={(e) => handleRemoveAddress(e)}>
                                                Eliminar domicilio
                                                {selectAddress.id_company}
                                            </button>
                                        )}

                                    </span>
                                </div>
                                
                                )
                            }
                                 
                        </div>
                    </div>

                    
                </div>

            </div>
            

            <button type="button" class="btn btn-primary" id='viewDeleteModal' data-bs-toggle="modal" data-bs-target="#deleteModal">
                Delete
            </button>


            <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Confirmar</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            Estas por borrar el registro
                        </div>
                        <div class="modal-footer">
                            
                            <button type="button" class="btn btn-delete" data-bs-dismiss="modal" onClick={callback}>Confirmar</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};


const VacationYearsInput = ({ data = [], edit = false, disabled = false }) => {
    const [vacationData, setVacationData] = useState([...data, { years: '', days: '' }]);
  
    const handleInputChange = (index, field, value) => {
      const updatedData = [...vacationData];
      updatedData[index] = {
        ...updatedData[index],
        [field]: parseInt(value) || ''
      };
  
      // Remover líneas vacías que no sean la última
      const filteredData = updatedData.filter(
        (entry, idx) => idx === updatedData.length - 1 || entry.years || entry.days
      );
  
      setVacationData(filteredData);
  
      // Agregar nueva entrada vacía si la última está completa
      const lastEntry = filteredData[filteredData.length - 1];
      if (lastEntry.years && lastEntry.days) {
        setVacationData([...filteredData, { years: '', days: '' }]);
      }
    };
  
    useEffect(() => {
      setVacationData([...data, { years: '', days: '' }]);
    }, [data]);
  
    return (
      <div className="vacation-input-component">
        <div className="vacation-input-header">
          <label htmlFor="">A partir de:</label>
          <label htmlFor="">Días de vacaciones:</label>
        </div>
  
        {vacationData.map((entry, index) => (
          <div key={index} className="vacation-input-item">
            <div className="c-input">
              <input
                type="number"
                min="1"
                value={entry.years || ''}
                onChange={(e) => handleInputChange(index, 'years', e.target.value)}
                className="form-control"
                disabled={disabled}
              />
              <p>año(s)</p>
            </div>
            <input
              type="number"
              min="12"
              disabled={disabled}
              value={entry.days || ''}
              onChange={(e) => handleInputChange(index, 'days', e.target.value)}
              className="form-control me-2"
              placeholder="Días"
            />
          </div>
        ))}
  
        <input
            type="hidden"
            name={edit ? "edit-vacaciones_anuales" : "vacaciones_anuales"}
            value={JSON.stringify(
                vacationData
                .filter(entry => entry.years && entry.days)
                .reduce((acc, { years, days }) => {
                    acc[years] = days;
                    return acc;
                }, {})
            )}
            
        />

      </div>
    );
  };

const CompanyVacationDisplay = ({ editCompany }) => {
    let vacationData = [];

    try {
        const rawData = editCompany?.benefits?.vacaciones_anuales;
        
        if (typeof rawData === 'string') {
        // Intenta parsear si es cadena JSON válida
        vacationData = JSON.parse(rawData);
        } else if (Array.isArray(rawData)) {
        // Si ya es un array, simplemente úsalo
        vacationData = rawData;
        } else {
        console.warn("Formato no compatible para vacaciones_anuales:", rawData);
        }
    } catch (error) {
        console.error("Error al parsear vacaciones_anuales:", error);
    }

    // Asegúrate de que vacationData tenga al menos una estructura válida si está vacío
    vacationData = Array.isArray(vacationData) && vacationData.length > 0 ? vacationData : [];
    return (
      <div>
        {vacationData && vacationData.length > 0 ? (
            <ul>
            {vacationData.map((entry, index) => (
                <li key={index}>
                <strong>{entry.years} año(s):</strong> {entry.days} días
                </li>
            ))}
            </ul>
        ) : (
            <p>No hay datos de vacaciones</p>
        )}
      </div>
    );
  };
  

  
export default AdminCompanies;
